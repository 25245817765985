<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-7">
        <h2 class="font-weight-bolder text-dark font-size-h3 mb-0">
          {{ $t("MENU.USER_GUIDE") }}
        </h2>
      </div>
      <div class="row">
        <div
          class="col-md-4 col-xxl-4 col-lg-12"
          v-for="item in items"
          v-bind:key="item.id"
        >
          <div class="card card-custom card-shadowless">
            <div class="card-body p-0">
              <div class="overlay">
                <div class="overlay-wrapper rounded bg-light text-center">
                  <b-img
                    class="mw-200 w-300px"
                    :src="createDownloadUrl(item.panduan_path)"
                    v-if="isPicture(item.filename)"
                  ></b-img>
                  <b-embed
                    type="video"
                    aspect="4by3"
                    poster="media/svg/files/mp4.svg"
                    v-if="isVideo(item.filename)"
                  >
                    <source
                      :src="createDownloadUrl(item.panduan_path)"
                      type="video/mp4"
                    />
                  </b-embed>
                  <b-embed
                    type="video"
                    aspect="4by3"
                    poster="media/svg/files/pdf.svg"
                    v-if="isPdf(item.filename)"
                  >
                    <source
                      :src="createDownloadUrl(item.panduan_path)"
                      type="pdf"
                    />
                  </b-embed>
                </div>
                <div class="overlay-layer">
                  <a
                    :href="createDownloadUrl(item.panduan_path)"
                    target="_blank"
                    class="btn font-weight-bolder btn-sm btn-light-primary"
                    >{{ $t("GENERAL_TEXT.DOWNLOAD") }}</a
                  >
                </div>
              </div>
              <div
                class="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column"
              >
                <a
                  href="#"
                  class="font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1"
                  >{{ item.panduan_name }}</a
                >
                <span class="font-size-lg">{{ item.panduan_description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <br />-->
  <!--  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">-->
  <!--    <template v-slot:title>-->
  <!--      {{ title }}-->
  <!--    </template>-->
  <!--    <template v-slot:body>-->
  <!--      <div v-for="item in items" v-bind:key="item.id">-->
  <!--        -->
  <!--      </div>-->
  <!--    </template>-->
  <!--  </KTCard>-->
</template>

<script>
// import KTCard from "@/view/content/Card.vue";
import { mapGetters } from "vuex";
import {
  GET_USER_GUIDE,
  SET_USER_GUIDE
} from "@/core/services/store/faq/user_guide.monule";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "UserGuide",
  data() {
    return {
      title: this.$t("MENU.USER_GUIDE"),
      items: []
    };
  },
  components: {
    // KTCard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FAQ"), route: "general-definition" },
      { title: this.$t("MENU.USER_GUIDE") }
    ]);
    this.getItems();
  },
  computed: mapGetters(["getUserGuideData"]),
  methods: {
    getItems() {
      this.$store.dispatch(GET_USER_GUIDE);
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_USER_GUIDE) {
          this.items = this.getUserGuideData;
        }

        // if (mutation.type === SET_REBUTTAL_STATEMENT_LOADING) {
        //   this.isBusy = this.get;
        // }
      });
    },
    createDownloadUrl(fileName) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "api/smartmapadmin/panduan_pengguna/download/" +
        fileName
      );
    },
    isPicture(fileName) {
      let splitFile = fileName.split(".").pop();
      return splitFile === "jpeg" || splitFile === "png" || splitFile === "jpg";
    },
    isVideo(fileName) {
      let splitFile = fileName.split(".").pop();
      return splitFile === "mp4" || splitFile === "avi" || splitFile === "mob";
    },
    isPdf(fileName) {
      let splitFile = fileName.split(".").pop();
      return splitFile === "pdf";
    }
  }
};
</script>

<style scoped></style>
